import {toast} from "react-toastify";
import {toastOptions} from "../utils/toast-options";

export function useToastErrorHandler<T extends (...args: any[]) => Promise<any>>(originalFunction: T, successMessage?: string, errorHandler?: (e: any) => void): (...args: Parameters<T>) => Promise<ReturnType<T>> {
    return async (...args: Parameters<T>): Promise<ReturnType<T>> => {
        let result
        try {
            result = await originalFunction(...args);
            if (successMessage) {
                toast.success(successMessage, toastOptions)
            }
        } catch (e: any) {
            if (e.data?.message) {
                toast.warning(e.data.message, toastOptions)
            } else if (e.data?.error) {
                toast.warning(e.data.error[0], toastOptions)
            } else if (e.message) {
                toast.warning(e.message, toastOptions)
            } else {
                toast.warning(e, toastOptions)
            }
            if (errorHandler) {
                errorHandler(e)
            }
        }
        return result;
    };
}
