import _ from "lodash";
import {Text, TextStyle} from 'pixi.js';
export class ActionButton extends Text {

    constructor(text: string, style: Partial<TextStyle>) {
        super(text, style);
    }

    toJson() {
        return {
            x: this.x,
            y: this.y,
            text: this.text,
            type: 'ActionButton',
            style: _.pick(this.style, ['fontFamily', 'fontSize', 'fill', 'align'])
        }
    }
}