import {Container, FederatedPointerEvent, Graphics, Point, Sprite, Texture} from "pixi.js";
import {reservedImageUrl, Seat, SeatStatus} from "./seat";
import {setDraggable} from "./make-draggable";
import {SeatAllocation} from "../../models/models";
import {SeatNumberGenerator} from "./seat-number-generator";
import {Label} from "./label";
import {v4 as uuidv4} from 'uuid';
import {ActionButton} from "./action-button";
export enum ColumnPattern {
    A_Z = "A-Z",
    Z_A = "Z-A",
    A_Z_WITHOUT_I = "A-Z-WITHOUT-I",
    Z_A_WITHOUT_I = "Z-A-WITHOUT-I",
    NUMERIC = "1,2,3",
    NUMERIC_REVERSE = "3,2,1",
    MANUAL = "manual"
}

export enum RowPattern {
    A_Z = "A-Z",
    Z_A = "Z-A",
    A_Z_WITHOUT_I = "A-Z-WITHOUT-I",
    Z_A_WITHOUT_I = "Z-A-WITHOUT-I",
    NUMERIC = "1,2,3",
    NUMERIC_REVERSE = "3,2,1",
    MANUAL = "manual"
}

export interface CreateSeatingBlockFromDimensionsInput {
    rows: number
    columns: number
    label: string
    columnPattern: ColumnPattern
    rowPattern: RowPattern
    manualRowPattern: string
    manualColumnPattern: string
    rowPatternLabelSide: string
}
export type TwoD = {
    x: number
    y: number
}



function addSeatEditOption(s: Seat, parent: any, onSeatEditHandler: any) {
    let pointerDownPoint: Point
    let pointerUpPoint: Point
    s.onpointerdown = (e: FederatedPointerEvent) => {
        pointerDownPoint = new Point(e.globalX, e.globalY)
    }
    s.onpointerup = (e: FederatedPointerEvent) => {
        pointerUpPoint = new Point(e.globalX, e.globalY)

        if (pointerUpPoint && pointerDownPoint && pointerUpPoint.x === pointerDownPoint.x && pointerUpPoint.y === pointerDownPoint.y) {
            onSeatEditHandler(s, (payload: any) => {
                if (payload.action === 'delete') {
                    s.visible = false
                }
                if (payload.action === 'save') {
                    s.rowLabel = payload.values.rowLabel
                    s.seatLabel = payload.values.seatLabel
                    s.seatNumber = payload.values.seatNumber
                }
            })

        }
    }
}

function addSeatHoverLabels(s: Seat, parent: any) {
    const rectangleHeight = 20
    const textToShow = s.rowLabel + s.seatLabel
    s.onpointerover = (e) => {
        if (seatMarker) parent.removeChild(seatMarker)
        seatMarker = new Container()
        const gr = new Graphics()
        gr.beginFill(0x000000)
        gr.drawRect(s.x, s.y - rectangleHeight * 1.5, textToShow.length * 12, rectangleHeight)
        gr.endFill()
        const text = new Label(textToShow, {
            fontFamily: 'Arial',
            fontSize: 12,
            fill: 0xffffff,
            align: 'center',
        })
        text.x = s.x + 5
        text.y = s.y - rectangleHeight*1.3

        gr.addChild(text)
        seatMarker.addChild(gr)
        parent.addChild(seatMarker)
    }
    const out = (e: any) => {
        if (seatMarker) {
            parent.removeChild(seatMarker);
            seatMarker = null
        }
    }
    s.ontouchmove = out
    s.onpointerleave = out
}
function createHeader(label: string, x: number, y: number) {
    const header = new Label(label, {
        fontFamily: 'Arial',
        fontSize: 10,
        fill: 0x000000,
        align: 'center',
    })
    header.x = x
    header.y = y
    return header

}

function createRowMarker(label: string, x: number, y: number, onSeatingBlockEditHandler?: any, seatingBlock?: SeatingBlock) {
    const rowMarker = new Label(label, {
        fontFamily: 'Arial',
        fontSize: 10,
        fill: 0x000000,
        align: 'center',
    })
    rowMarker.x = x
    rowMarker.y = y
    rowMarker.interactive = true
    if (onSeatingBlockEditHandler && seatingBlock) {
        rowMarker.onpointerup = (e) => {
            onSeatingBlockEditHandler(seatingBlock, (payload: any) => {
                if (payload.action === 'rotate') {
                    seatingBlock.angle = payload.values.angle
                }
                if (payload.action === 'delete') {
                    seatingBlock.destroy()
                }
                if (payload.action === 'setLabelSide') {
                    seatingBlock.rowPatternLabelSide = payload.values.labelSide
                }
            })
        }
    }
    return rowMarker
}

export type CreateSeatingBlockFromSavedStateInput = TwoD &  {
    children: any[]
    type?: string
    label: string
    rowPatternLabelSide: string
    rows: number
    columns: number
    angle: number
}
let seatMarker: any
export class SeatingBlock extends Container {
    type: string = "SeatingBlock"
    id: string = ""
    label: string = ""
    rows: number = 0
    columns: number = 0
    columnPattern: ColumnPattern = ColumnPattern.NUMERIC
    rowPattern: RowPattern = RowPattern.A_Z
    manualRowPattern: string = ""
    manualColumnPattern: string = ""
    rowPatternLabelSide: string = ""
    static createFromDimensions(props: CreateSeatingBlockFromDimensionsInput, onSeatEditHandler: any, onSeatingBlockEditHandler: any) {
        const sb = new SeatingBlock()
        sb.label = props.label
        sb.interactive = true
        sb.rows = props.rows
        sb.columns = props.columns
        sb.rowPattern = props.rowPattern
        sb.columnPattern = props.columnPattern
        sb.manualRowPattern = props.manualRowPattern
        sb.manualColumnPattern = props.manualColumnPattern
        sb.rowPatternLabelSide = props.rowPatternLabelSide
        setDraggable(sb)
        sb.drawFromDimensions(onSeatEditHandler, onSeatingBlockEditHandler)
        return sb
    }

    static async createSeatingBlockFromSavedState(seatingBlockSavedState: CreateSeatingBlockFromSavedStateInput, draggable: boolean, onSeatEditHandler: any, onSeatingBlockEditHandler: any) {
        const sb = new SeatingBlock()
        sb.interactive = true
        sb.id = `${Math.random()}`
        if (draggable) {
            setDraggable(sb)
        }
        sb.x = seatingBlockSavedState.x
        sb.y = seatingBlockSavedState.y
        sb.label = seatingBlockSavedState.label
        sb.rowPatternLabelSide = seatingBlockSavedState.rowPatternLabelSide
        sb.rows = seatingBlockSavedState.rows
        sb.columns = seatingBlockSavedState.columns
        if (seatingBlockSavedState.angle) {
            sb.angle = seatingBlockSavedState.angle
        }
        let seatHeight = 0
        let seatWidth = 0
        for (const c of seatingBlockSavedState.children) {
            if (c.type === 'Seat') {
                const seat = await Seat.createNonInteractiveSeat()
                Object.assign(seat, c);
                sb.addChild(seat)
                addSeatHoverLabels(seat, sb)
                addSeatEditOption(seat, sb, onSeatEditHandler)
                if (seat.xIndex === 0) {
                    sb.addChild(createRowMarker(seat.originalRowLabel, sb.rowPatternLabelSide === "left" ? (-1 * seat.width) :  sb.columns * seat.width + 15, c.y + 2, onSeatingBlockEditHandler, sb))

                }
                seatHeight = seat.height
                seatWidth = seat.width
            }
        }
        sb.addChild(createHeader(sb.label, 0, -1 * seatHeight))
        return sb
    }

    static async createSeatingBlockForSeatPicking(seatingBlockSavedState: CreateSeatingBlockFromSavedStateInput,
                                                  seatAllocations: SeatAllocation[], draggable: boolean, showSeatingLabels: boolean) {
        const sb = new SeatingBlock()
        sb.interactive = false
        sb.id = `${Math.random()}`
        sb.x = seatingBlockSavedState.x
        sb.y = seatingBlockSavedState.y
        sb.columns = seatingBlockSavedState.columns
        sb.label = seatingBlockSavedState.label
        if (seatingBlockSavedState.angle) {
            sb.angle = seatingBlockSavedState.angle
        }
        sb.rowPatternLabelSide = seatingBlockSavedState.rowPatternLabelSide
        let seatHeight = 0
        let seatWidth = 0
        for (const c of seatingBlockSavedState.children) {
            if (c.type === 'Seat') {
                const allocation = seatAllocations.find(sa => sa.seatId === c.id)
                const seat = await Seat.createInteractiveSeat(c.id,  c.seatNumber,allocation ? allocation.status : SeatStatus.OPEN)
                Object.assign(seat, c);
                if (!draggable) {
                    await seat.setDetailedTextures()
                }
                sb.addChild(seat)
                seatHeight = seat.height
                seatWidth = seat.width
                addSeatHoverLabels(seat, sb)
                if (seat.xIndex === 0) {
                    sb.addChild(createRowMarker(seat.originalRowLabel, sb.rowPatternLabelSide === "left" ? -1 * seatWidth :  (Number(sb.columns) * seatWidth) + 10, c.y + 2))
                }
            }
        }
        sb.addChild(createHeader(sb.label, 0, -1 * seatHeight))
        return sb
    }

    private async drawFromDimensions(onSeatEditHandler: any, onSeatingBlockEditHandler: any) {
        this.x = 50
        this.y = 50
        let x = 0
        let y = 0
        const seatNumberGenerator = new SeatNumberGenerator(this.rowPattern, this.columnPattern, this.rows, this.columns, this.manualRowPattern, this.manualColumnPattern)
        let rowLabel = ''
        let seatHeight = 0
        let seatWidth = 0
        for (let i=0; i<this.rows; i++) {
            for (let j=0; j<this.columns; j++) {
                const s = await Seat.createNonInteractiveSeat()
                const seatInfo = seatNumberGenerator.next()
                s.xIndex = j
                s.yIndex = i
                s.seatNumber = this.label + ' ' + seatInfo.row + seatInfo.seat
                s.rowLabel = seatInfo.row
                s.seatLabel = seatInfo.seat
                s.originalRowLabel = seatInfo.row
                s.originalSeatLabel = seatInfo.seat
                rowLabel = s.originalRowLabel
                s.id = uuidv4()
                s.x = x
                s.y = y
                seatHeight = s.height
                seatWidth = s.width
                this.addChild(s)
                addSeatHoverLabels(s, this)
                addSeatEditOption(s, this, onSeatEditHandler)
                x += s.width + 1
            }
            this.addChild(createRowMarker(rowLabel, this.rowPatternLabelSide === "left" ? -1 * seatWidth :  this.columns * seatWidth + 10, y + 2, onSeatingBlockEditHandler, this))

            x = 0
            y += seatHeight + 1
        }
        this.addChild(createHeader(this.label, 0, -1 * seatHeight))

    }

    toJson(): any {
        return {
            id: this.id,
            type: this.type,
            label: this.label,
            angle: this.angle,
            rowPatternLabelSide: this.rowPatternLabelSide,
            columns: this.columns,
            rows: this.rows,
            x: this.x,
            y: this.y
        }
    }
}
