"use client"
import {Stage} from '@pixi/react';
import {Application} from '@pixi/app';
import {useEffect, useRef, useState} from "react";
import {SeatingChartContainer} from "../app/seating-map/seating-chart-container";

type SeatingChartFromProps = {
    seatingChartContainer?: SeatingChartContainer
    bound?: boolean
}

export default function SeatingChartForm({seatingChartContainer, bound}: SeatingChartFromProps) {
    const [app, setApp] = useState<Application>()
    const [loading, setLoading] = useState(false)
    const stageContainer = useRef(null)
    useEffect(() => {
        if (!app || !seatingChartContainer || !stageContainer) return;
        if (app && !seatingChartContainer) {
            app.stage.removeChildren()
        }
        const load = async () => {
            if (typeof window !== "undefined") {
                app.resizeTo = stageContainer.current as unknown as HTMLElement
                const pv = await import('pixi-viewport');
                const viewport = new pv.Viewport({
                    screenWidth: window.innerWidth,
                    screenHeight: window.innerHeight,
                    events: app.renderer.events // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
                })
                viewport.addChild(seatingChartContainer)
                app.stage.addChild(viewport)
                viewport
                    .drag()
                    .pinch()
                    .wheel()
                    .decelerate()

                if (bound) {
                    setLoading(true)
                    setTimeout(() => {
                        seatingChartContainer.boundItUp(viewport)
                        setLoading(false)
                    }, 2000)
                }
            }


        }

        load()

    }, [app, seatingChartContainer, stageContainer])

    return <>
        <div className={"flex"}>
        <div ref={stageContainer} className={"flex flex-col w-full max-w-full"}>
            <Stage options={ { backgroundColor: '#eee'} }  onMount={setApp}></Stage>
        </div>
    </div>
        <dialog className={`modal ${loading ? 'modal-open' : ''}`}>
            <div className="modal-box w-full text-center max-h-full h-max bg-gray-200">
                <span className="loading loading-bars loading-lg"></span>
            </div>
        </dialog>
    </>
};
