import {Container, FederatedPointerEvent, Sprite, Texture} from "pixi.js";
import {ActionButton} from "./action-button";
import {setDraggable} from "./make-draggable";

export default class GenericObject extends Container {

    url: string
    type = 'GenericObject'
    constructor(props: any, showDeleteButton: boolean = false) {
        super();
        this.url = props.url

        Texture.fromURL(this.url).then(t => {
            const sprite = new Sprite(t)
            // big stage is the right size to begin with
            if (this.url !== 'https://i.imgur.com/LLP5yJ8.png') {
                sprite.width = 50
                sprite.height = 50
            }
            this.addChild(sprite)
            if (showDeleteButton) {
                this.addDeleteButton()
                setDraggable(this)
            }
            if (props.x && props.y) {
                this.x = props.x
                this.y = props.y
            } else {
                this.x = 50
                this.y = 50
            }
        })
    }

    addDeleteButton() {
        const deleteButton = new ActionButton("X", {
            fontFamily: 'Arial',
            fontSize: 10,
            fill: 0x000000,
            align: 'center',

        })
        deleteButton.x = 0
        deleteButton.y = -10
        deleteButton.interactive = true
        deleteButton.on('pointerdown', (e: FederatedPointerEvent) => {
            e.stopPropagation()
            this.parent.removeChild(this)
        })
        this.addChild(deleteButton)
    }

    toJson(): any {
        return {
            type: this.type,
            x: this.x,
            y: this.y,
            url: this.url
        }
    }


}