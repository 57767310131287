"use client"
import {FederatedPointerEvent, Sprite, Texture} from "pixi.js";
import {SeatingChartContainer} from "./seating-chart-container";
import {v4 as uuidv4} from 'uuid';
export const occupiedImageUrl = 'https://i.imgur.com/SvETeIl.png';
export const openImageUrl = 'https://i.imgur.com/kCHY0kV.png';
export const selectedImageUrl = 'https://i.imgur.com/5BmurRv.png'
export const blockedImageUrl = 'https://i.imgur.com/maoMyjo.png'
export const reservedImageUrl = 'https://i.imgur.com/EMNadzc.png'
export enum SeatStatus {
    OPEN = "open",
    BLOCKED = "blocked",
    TAKEN = "taken",
    RESERVED = "reserved",
    RESERVED_BY_CURRENT_CART = "selected"
}

export type SeatNumberAndId = {
    seatNumber: string
    seatId: string
}

export class Seat extends Sprite {
    status: SeatStatus = SeatStatus.OPEN
    id: string = ""
    seatNumber: string = ""
    rowLabel: string = ""
    seatLabel: string = ""
    originalRowLabel: string = ""
    originalSeatLabel: string = ""
    xIndex = 0
    yIndex = 0
    editing = false

    constructor() {
        super();
        this.on('pointerdown', this.seatClicked)
    }

    seatClicked(e: FederatedPointerEvent) {
        const container = this.parent?.parent as SeatingChartContainer;
        if (container.requestToSelect(this)) {
            this.status = SeatStatus.RESERVED_BY_CURRENT_CART
            container.seatModified(this, e);
            this.setTexture();
        } else if (container.requestToUnselect(this)) {
            this.status = SeatStatus.OPEN
            container.seatModified(this, e);
            this.setTexture();
        }
    }

    static async createNonInteractiveSeat(): Promise<Seat> {
        const seat = new Seat()
        seat.status = SeatStatus.OPEN
        seat.interactive = true
        seat.id = uuidv4()
        seat.scale = {x: .10, y: .10}
        seat.texture = await Texture.fromURL(openImageUrl)
        return seat
    }

    static async createInteractiveSeat(id: string, seatNumber: string, status: SeatStatus): Promise<Seat> {
        const seat = new Seat()
        seat.status = status
        seat.interactive = true
        seat.id = id
        seat.seatNumber = seatNumber
        seat.scale = {x: .10, y: .10}
        seat.setTexture()
        return seat
    }

    setStatus(status: SeatStatus) {
        this.status = status
        this.setTexture()
    }

    private async setTexture() {
        if (this.status === SeatStatus.RESERVED_BY_CURRENT_CART) {
            this.texture = await Texture.fromURL(selectedImageUrl)
        } else if (this.status === SeatStatus.OPEN) {
            this.texture = await Texture.fromURL(openImageUrl)
        } else {
            this.texture = await Texture.fromURL(occupiedImageUrl)
        }
    }

    toJson(): any {
        return {
            id: this.id,
            seatNumber: this.seatNumber,
            rowLabel: this.rowLabel,
            seatLabel: this.seatLabel,
            originalRowLabel: this.originalRowLabel,
            originalSeatLabel: this.originalSeatLabel,
            xIndex: this.xIndex,
            yIndex: this.yIndex,
            visible: this.visible,
            type: 'Seat',
            x: this.x,
            y: this.y,
            scale: {
                x: this.scale.x,
                y: this.scale.y
            }
        }
    }

    async setDetailedTextures() {
        if (this.status === SeatStatus.RESERVED_BY_CURRENT_CART || this.status == SeatStatus.RESERVED) {
            this.texture = await Texture.fromURL(reservedImageUrl)
        } else if (this.status === SeatStatus.OPEN) {
            this.texture = await Texture.fromURL(openImageUrl)
        } else if (this.status === SeatStatus.BLOCKED) {
            this.texture = await Texture.fromURL(blockedImageUrl)
        } else if (this.status === SeatStatus.TAKEN) {
            this.texture = await Texture.fromURL(occupiedImageUrl)
        }
    }
}