import {Text, TextStyle} from 'pixi.js';
import _ from "lodash";

export class Label extends Text {
    type = 'Label'

    constructor(text: string, style: Partial<TextStyle>) {
        super(text, style);
    }

    toJson() {
        return {
            x: this.x,
            y: this.y,
            text: this.text,
            type: 'Label',
            style: _.pick(this.style, ['fontFamily', 'fontSize', 'fill', 'align'])
        }

    }
}