import {autoDetectRenderer, Container, FederatedPointerEvent, Graphics, Sprite, Texture} from "pixi.js";
import {
    CreateSeatingBlockFromDimensionsInput,
    CreateSeatingBlockFromSavedStateInput,
    SeatingBlock
} from "./seating-block";
import {Serialized} from "./serialized";
import {Seat, SeatNumberAndId, SeatStatus, selectedImageUrl} from "./seat";
import {SeatAllocation} from "../../models/models";
import {Rubberband} from "./make-rubberband";
import GenericObject from "./generic-object";
import {Viewport} from "pixi-viewport";

export type OnAdminSeatSelectProps = {
    openSeats: Seat[]
    blockedSeats: Seat[]
}
const backgroundImageUrl = "https://i.imgur.com/mjeNKSi.png"
export class SeatingChartContainer extends Container {

    draggable: boolean
    showDeleteOptionOnObjects: boolean
    seatSelectionsRequired: number
    seatsSelected: SeatNumberAndId[] = []
    seatAllocations: SeatAllocation[]
    savedState: any[]
    showSeatingLabels: boolean
    seatPicking: boolean
    manageSeats: boolean
    onAdminSeatSelect?: (props: OnAdminSeatSelectProps) => void
    onSeatEditHandler: (seat: Seat, cb: any) => void
    onSeatingBlockEditHandler: (seatingBlock: SeatingBlock, cb: any) => void

    onSeatingChartUpdate: (seatsSelected: SeatNumberAndId[]) => undefined
    constructor({
                    manageSeats = false,
                    seatPicking = false,
                    draggable = true,
                    showSeatingLabels = true,
                    selectable = false,
                    onSeatEditHandler = (s: Seat, cb: any) => "",
                    onSeatingBlockEditHandler = (s: SeatingBlock, cb: any) => "",
                    showDeleteOptionOnObjects = false,
                    seatSelectionsRequired = 0,
                    onSeatingChartUpdate = (s: SeatNumberAndId[]) => {console.log("not implemented"); return undefined},
                    seatAllocations = [],
                    savedState = [],
                    onAdminSeatSelect = (props: OnAdminSeatSelectProps) => console.log("not implemented")

}: {
        onSeatEditHandler?: (seat: Seat, cb: any) => void,
        onSeatingBlockEditHandler?:  (s: SeatingBlock, cb: any) => void,
        manageSeats?: boolean
        showSeatingLabels?: boolean
        seatPicking?: boolean
        selectable?: boolean,
        draggable?: boolean,
        showDeleteOptionOnObjects?: boolean,
        onSeatingChartUpdate?: (seatsSelected: SeatNumberAndId[]) => undefined,
        seatSelectionsRequired?: number,
        seatAllocations?: SeatAllocation[],
        savedState?: CreateSeatingBlockFromSavedStateInput[],
        onAdminSeatSelect?: (props: OnAdminSeatSelectProps) => void

    }) {
        super();
        this.onSeatEditHandler = onSeatEditHandler
        this.onSeatingBlockEditHandler = onSeatingBlockEditHandler
        this.manageSeats = manageSeats
        this.seatPicking = seatPicking
        this.draggable = draggable
        this.showSeatingLabels = showSeatingLabels
        this.savedState = savedState
        this.seatAllocations = seatAllocations
        this.seatSelectionsRequired = seatSelectionsRequired
        this.interactive = true
        this.onSeatingChartUpdate = onSeatingChartUpdate
        this.onAdminSeatSelect = onAdminSeatSelect
        if (selectable || draggable) {
            // just so everything is clickable for rubberbanding
            Texture.fromURL(backgroundImageUrl).then(res => {
                const sprite = new Sprite(res)
                sprite.alpha = 0
                this.addChild(sprite)
                if (this.savedState) {
                    this.load()
                }
            }).catch(e => console.log(e))
        } else {
            if (this.savedState) {
                this.load()
            }
        }

        if (selectable) {
            new Rubberband(this, this.containsSeatsCallback.bind(this))
        }
        this.draggable = !selectable
        this.showDeleteOptionOnObjects = showDeleteOptionOnObjects
    }

    async containsSeatsCallback(seats: Seat[]) {
        let oldTextures: Texture[] = []
        const blockedAndOpenSeats = seats.filter(s => s.status === SeatStatus.OPEN || s.status === SeatStatus.BLOCKED)
        for (const s of blockedAndOpenSeats) {
            oldTextures.push(s.texture)
            s.texture = await Texture.fromURL(selectedImageUrl)
        }
        this.on('pointerdown', (event) => {
            if (!oldTextures.length) return
            for (const s of blockedAndOpenSeats) {
                s.texture = oldTextures.shift() as Texture
            }
        })

        if (this.onAdminSeatSelect) {
            const openSeats = blockedAndOpenSeats.filter(s => s.status === SeatStatus.OPEN)
            const blockedSeats = blockedAndOpenSeats.filter(s => s.status === SeatStatus.BLOCKED)
            this.onAdminSeatSelect({
                blockedSeats,
                openSeats
            })
        }

    }

    requestToSelect(seat: Seat): boolean {
        return seat.status === SeatStatus.OPEN && !this.seatsSelected.map(s => s.seatId).includes(seat.id) && this.seatsSelected.length < this.seatSelectionsRequired
    }

    requestToUnselect(seat: Seat): boolean {
        return seat.status === SeatStatus.RESERVED_BY_CURRENT_CART && this.seatsSelected.map(s => s.seatId).includes(seat.id)
    }

    seatModified(seat: Seat, e: FederatedPointerEvent) {
        if (seat.status === SeatStatus.RESERVED_BY_CURRENT_CART) {
            this.seatsSelected.push({seatId: seat.id, seatNumber: seat.seatNumber})
            this.onSeatingChartUpdate(this.seatsSelected)
        } else if (seat.status === SeatStatus.OPEN) {
            this.seatsSelected = this.seatsSelected.filter(item => item.seatId !== seat.id)
            this.onSeatingChartUpdate(this.seatsSelected)
        }
    }


    boundItUp(viewport: Viewport) {
        const bounds = viewport.getLocalBounds()
        var graphics = new Graphics();
        graphics.beginFill(0xFFFF00);
        graphics.lineStyle(5, 0xFF0000);
        graphics.alpha = 0.2
        graphics.drawRect(bounds.x, bounds.y, bounds.width, bounds.height);
        viewport.animate({
            scale: bounds.width / viewport.width
        })
        viewport.moveCorner(bounds.x, bounds.y)
        viewport.ensureVisible(bounds.x, bounds.y, bounds.width, bounds.height, true)
    }

    addObject(params: {type: string}) {
        if (params.type === 'washroom') {
            this.addChild(new GenericObject({url: 'https://i.imgur.com/AcOdn9U.png'}, true))
        } else if (params.type === 'stage') {
            this.addChild(new GenericObject({url: 'https://i.imgur.com/lNrGnmt.png'}, true))
        } else if (params.type === 'bar') {
            this.addChild(new GenericObject({url: 'https://i.imgur.com/nE9vUBM.png'}, true))
        } else if (params.type === 'entrance') {
            this.addChild(new GenericObject({url: 'https://i.imgur.com/dmUOZx3.png'}, true))
        } else if (params.type === 'big-stage') {
            this.addChild(new GenericObject({url: 'https://i.imgur.com/LLP5yJ8.png'}, true))
        } else if (params.type === 'accessible') {
            this.addChild(new GenericObject({url: 'https://i.imgur.com/5mmkGDx.png'}, true))
        }
    }

    addSeatingBlock(values: CreateSeatingBlockFromDimensionsInput) {
        const block = SeatingBlock.createFromDimensions(values, this.onSeatEditHandler, this.onSeatingBlockEditHandler)
        this.addChild(block)
    }

    async load() {
        for (const s of this.savedState) {
            let newElement
            if ((this.seatPicking || this.manageSeats) && s.type === 'SeatingBlock') {
                newElement = await SeatingBlock.createSeatingBlockForSeatPicking(s as CreateSeatingBlockFromSavedStateInput, this.seatAllocations, this.draggable, this.showSeatingLabels)
            } else if (s.type === 'SeatingBlock') {
                newElement = await SeatingBlock.createSeatingBlockFromSavedState(s as CreateSeatingBlockFromSavedStateInput, this.draggable, this.onSeatEditHandler, this.onSeatingBlockEditHandler)
            } else if (s.type === 'GenericObject') {
                newElement = new GenericObject(s, this.showDeleteOptionOnObjects)
            }
            if (newElement) {
                this.addChild(newElement)
            }
        }
    }

    toJson(): any {
        let result: Serialized[] = []
        this.internalSave(this.children, result)
        return result
    }

    internalSave(children: any, json: Serialized[]) {
        for (const c of children) {
            if (c.toJson) {
                const j = c.toJson()
                j.children = []
                json.push(j)
                this.internalSave(c.children, j.children)
            }
        }
    }

}
